define("discourse/plugins/paczki-pm-auto-responder-for-admins/discourse/templates/connectors/sidebar-footer-actions/pm-auto-responder-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showInSidebar}}
    <AutoPmToggler/>
  {{/if}}
  
  */
  {
    "id": "FHDHSsYa",
    "block": "[[[41,[30,0,[\"showInSidebar\"]],[[[1,\"  \"],[8,[39,1],null,null,null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"auto-pm-toggler\"]]",
    "moduleName": "discourse/plugins/paczki-pm-auto-responder-for-admins/discourse/templates/connectors/sidebar-footer-actions/pm-auto-responder-button.hbs",
    "isStrictMode": false
  });
});